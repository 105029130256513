//colors
$color_background: #FFF;
$color_text: #222;
$color_main: #3fc380;
$color_separator: #888;
$color_code: #AAA;

$color_background_dark: #303030;
$color_text_dark: #FFF  ;
$color_main_dark: #3fc380;
$color_separator_dark: #888;

//fonts
$font_open-sans: 'Open Sans';
$font_sans-serif: sans-serif;

html {
  --color-background: #{$color_background};
  --color-text: #{$color_text};
  --color-main: #{$color_main};
  background-color: var(--color-background);
}

html[data-theme='dark'] {
  --color-background: #{$color_background_dark};
  --color-text: #{$color_text_dark};
  --color-main: #{$color_main_dark};
  background-color: var(--color-background);
}

//@extend-elements
//original selectors
//header::before, article::before
%extend_1 {
    content: attr(data-text);
    height: max-content;
    position: absolute;
    top: 0;
    right: 0px;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    font-size: 20px;
    color: var(--color-main);
    opacity: .3;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.print_show {
    display: none;
}

.print_hide {
    display: inherit;
}

body {
    font-family: $font_open-sans, $font_sans-serif;
    background-color: var(--color-background);
    color: var(--color-text);
    margin: 20px;
    position: relative;

    // hide while loading
    display: none;
    &.loaded {
        display: block;
    }
}

h1, i, a {
    color: var(--color-main);
}

hr {
    border-top: solid var(--color-separator) 1px;
    margin: 50px;
}

code {
    background: $color_code;
    padding: 5px;
    border-radius: 5px;
}

aside {
    text-align: center;
    color: var(--color-main);

    span {
      margin: 20px;
    }

    button {
        cursor: pointer;
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;

        &.active {
            text-decoration: underline;
        }
    }
}

header {
    position: relative;

    div>* {
        font-weight: 400;
        font-size: 1.5em;
        line-height: 1.5em;
        display: inline-block;
    }

    [data-template] {
        display: none;

        &.loaded {
            display: block;
        }
    }
}

article {
    position: relative;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 1.5em;

    &:nth-of-type(2) {
        p {
            line-height: 2.5em;
        }

        span {
            border: solid var(--color-main) 2px;
            padding: 5px;
            margin: 4px;
        }
    }

    &:nth-of-type(4) {
        font-size: 1em;
        line-height: 1em;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        p {
            margin: 10px;
        }
    }

    &:last-of-type {
        font-size: .8em;
        text-align: center;
    }

    > [data-template]:not(span) {
        display: none;

        &.loaded {
            display: block;

            &[data-template="contact"] {
                display: flex;
                flex-direction: column;
            }
        }
    }

    > [data-template="career"] {
        li {
            margin: 30px 0;

            p {
                font-style: italic;
                font-size: 0.8em;
            }

            b {
                text-decoration: underline;
            }
        }
    }
}

header,
article {
    padding-right: 40px;
    
    &.loaded {
        &::before {
            @extend %extend_1;
        }
    }
}

ul>li {
    list-style-type: none;
    margin: 15px 0;
}

@media screen and(min-width: 480px) {
    header div>* {
        font-size: 1.8em;
    }

    article:nth-of-type(2) {
        font-size: 1.8em;
    }
}

@media screen and(min-width: 768px) {
    header div > * {
        font-size: 2.1em;
    }

    article {
        &:nth-of-type(2) {
            font-size: 2.1em;

            span {
                padding: 8px;
            }
        }

        > [data-template] {
            &.loaded {
                &[data-template="contact"] {
                    flex-direction: row;
                }
            }
        }
    }
}

@media screen and(min-width: 1024px) {
    body {
        max-width: 984px;
        margin: 0 auto 20px;
    }

    article {
        font-size: 1.8em;

        &:nth-of-type(4) {
            font-size: 1.5em;
        }
    }

    header div >* {
        font-size: 2.5em;
    }
}
