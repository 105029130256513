html {
  --color-background: #FFF;
  --color-text: #222;
  --color-main: #3fc380;
  background-color: var(--color-background);
}

html[data-theme=dark] {
  --color-background: #303030;
  --color-text: #FFF;
  --color-main: #3fc380;
  background-color: var(--color-background);
}

header.loaded::before,
article.loaded::before {
  content: attr(data-text);
  height: max-content;
  position: absolute;
  top: 0;
  right: 0px;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  font-size: 20px;
  color: var(--color-main);
  opacity: 0.3;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.print_show {
  display: none;
}

.print_hide {
  display: inherit;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 20px;
  position: relative;
  display: none;
}
body.loaded {
  display: block;
}

h1, i, a {
  color: var(--color-main);
}

hr {
  border-top: solid var(--color-separator) 1px;
  margin: 50px;
}

code {
  background: #AAA;
  padding: 5px;
  border-radius: 5px;
}

aside {
  text-align: center;
  color: var(--color-main);
}
aside span {
  margin: 20px;
}
aside button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
}
aside button.active {
  text-decoration: underline;
}

header {
  position: relative;
}
header div > * {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.5em;
  display: inline-block;
}
header [data-template] {
  display: none;
}
header [data-template].loaded {
  display: block;
}

article {
  position: relative;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.5em;
}
article:nth-of-type(2) p {
  line-height: 2.5em;
}
article:nth-of-type(2) span {
  border: solid var(--color-main) 2px;
  padding: 5px;
  margin: 4px;
}
article:nth-of-type(4) {
  font-size: 1em;
  line-height: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
article:nth-of-type(4) p {
  margin: 10px;
}
article:last-of-type {
  font-size: 0.8em;
  text-align: center;
}
article > [data-template]:not(span) {
  display: none;
}
article > [data-template]:not(span).loaded {
  display: block;
}
article > [data-template]:not(span).loaded[data-template=contact] {
  display: flex;
  flex-direction: column;
}
article > [data-template=career] li {
  margin: 30px 0;
}
article > [data-template=career] li p {
  font-style: italic;
  font-size: 0.8em;
}
article > [data-template=career] li b {
  text-decoration: underline;
}

header,
article {
  padding-right: 40px;
}
ul > li {
  list-style-type: none;
  margin: 15px 0;
}

@media screen and (min-width: 480px) {
  header div > * {
    font-size: 1.8em;
  }

  article:nth-of-type(2) {
    font-size: 1.8em;
  }
}
@media screen and (min-width: 768px) {
  header div > * {
    font-size: 2.1em;
  }

  article:nth-of-type(2) {
    font-size: 2.1em;
  }
  article:nth-of-type(2) span {
    padding: 8px;
  }
  article > [data-template].loaded[data-template=contact] {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  body {
    max-width: 984px;
    margin: 0 auto 20px;
  }

  article {
    font-size: 1.8em;
  }
  article:nth-of-type(4) {
    font-size: 1.5em;
  }

  header div > * {
    font-size: 2.5em;
  }
}
/*# sourceMappingURL=index.ffa9a177.css.map */
